import React from 'react';
import CardElement from './CardElement';
import { RiArrowDownLine } from 'react-icons/ri';
;
const LatestPosts = ({ data }) => {

    const posts = data?.edges
    .filter(edge => !!edge.node?.frontmatter?.date)
    .map(post => {
        const element = post.node.frontmatter
        return (
          <CardElement
            key={post.node?.id}
            image={element?.featuredImage}
            title={element?.title}
            subtitle={element?.subtitle}
            date={element?.date}
            slug={element?.slug}
          />)})
    ;
    return (
        <section id="latest" className="wrapper style5 special latest">
            <h2>Latest in Blog <span className="icon"><RiArrowDownLine/></span></h2>
            <div className="inner posts">
                <section>
                    <div className="grid latest">
                        {posts}
                    </div>
                </section>
            </div>
            <a href='/blog/' className="button primary">
                More posts
            </a>
        </section>
    );
}

export default LatestPosts;
