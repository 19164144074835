import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
import LatestPosts from '../components/LatestPosts';
import RecommendationList from '../components/RecommendationList';

const IndexPage = ({ data }) => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="cta">
              <a href="/#" className="button primary">
                Contact
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            TEAM FIRST
          </h2>
          <p>
          I am an innovative Entrepreneur and Business Angel who has a passion for building winning teams and organisations.
          After building GuideSmiths (a successful tech company) for a number of years, me and my Co-Founder decided to exit the business in 2021, after having reached 9M€ revenue with a team of 120 people in 3 countries.
          </p>
          <p>
          After the GuideSmiths venture, I came to realise how key building a great team is when it comes to growing, scaling up and selling your company. I learnt there is science behind the creation of high-performance teams, and so I created my new project <a href="https://teamhackers.es">Team Hackers</a> to help other businesses scale up.
          </p>
          {/* <p>
          After the GuideSmiths venture, I came to realise how hard and stressful is to scale such type of business. As I understand its problems, I decided to do something about it. My new mission is called <a href="https://mangroove.io">mangroove.io</a> and is here to help other digital agencies scale, supporting them at operating and selling - more, faster and better.
          </p> */}
          {/* <p>
          I truly believe in a healthy combination between people, business and tech to win. They need each other to be able to thrive.
          </p> */}
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Tech</span>
            </span>
          </li>
          <li>
            <span className="icon fa-chart-bar major style3">
              <span className="label">Business</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-users major style3">
              <span className="label">Teams</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    {/* <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Tech
          </h2>
          <p>
            Technology is the main driver in economy and society nowadays. Most businesses could go digital and if you're not up to date, you'll be left behind.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Business
          </h2>
          <p>
            Decision making, tech architecture, financial models, risk management and leadership are all among the key factors that determine which business will thrive and which will struggle.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Teams
          </h2>
          <p>
            It's all about your people. Establishing a culture, a work method and efficient communication and collaboration channels are essential aspects for high performance teams.
          </p>
        </div>
      </section>
    </section> */}

    <LatestPosts data={data.blog} />

    <RecommendationList data={data.recommendation} />

    <section id="cta" className="wrapper style4">
      <div className="inner contact">
        <header>
          <h2>Stay in touch</h2>
          <p>
            If you liked to stay in touch, you could contact me through email or my social media links below.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="mailto:felipe.polo@prinv.es" className="button fit primary">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

export const lastPostsQuery = graphql`
   {
    blog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog" } } }
      limit: 3
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
			      title
            description
            featuredImage
          }
        }
      }
    },
    recommendation: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "recommendation" } } }
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
			      author
			      author_link
            description
          }
        }
      }
    }
  }
`
